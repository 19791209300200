import React, { useState } from 'react';
import { Card, Input, Button, InputNumber, Form, Modal, message} from 'antd';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const backendPort = process.env.REACT_APP_BACKEND_PORT;
const baseUrl = `${backendUrl}:${backendPort}`;

function EditProductPage({ products, setProducts, setPage }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const currentProduct = products[currentIndex];

    const validateCurrentProduct = () => {
        const { name, price } = currentProduct;
        if (!name || price === undefined || price === null || price === '') {
            message.error("请填写所有商品的名称和价格！")
            return false;
        }
        return true;
    };


    const handlePreview = () => {
        setPreviewImage(currentProduct.img_url);
        setPreviewVisible(true);
    };


    const handlePriceChange = (value) => {
        const updatedProducts = products.map((product, index) =>
            index === currentIndex ? { ...product, price: value } : product
        );
        setProducts(updatedProducts);
    };

    const handleNameChange = (event) => {
        const updatedProducts = products.map((product, index) =>
            index === currentIndex ? { ...product, name: event.target.value } : product
        );
        setProducts(updatedProducts);
    };
    // For the Next button
    const handleNext = () => {
        if (validateCurrentProduct()) {
            setCurrentIndex(currentIndex + 1);
        }
    };

// For the Submit button
    const handleSubmit = async () => {
        if (!validateCurrentProduct()) {
            return;  // Stop the submission if the current product is not valid
        }

        const formData = new FormData();

        let index = 0;
        products.forEach(product => {
            formData.append(`items[${index}][name]`, product.name);
            formData.append(`items[${index}][price]`, product.price);
            formData.append(`items[${index}][wechat]`, product.wechatID);
            formData.append(`items[${index}][pickup_method]`, product.deliveryMethod);

            if (product.img_file) {
                formData.append(`items[${index}][image_file]`, product.img_file, product.img_file.name);
            }
            index++;
        });
        formData.append('count', products.length);

        axios.post(`/api/items/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            console.log('Success:', response.data);
        }).catch(error => {
            console.error('Failed to submit products:', error);
        });

        setPage('submissionSuccess');
    };





    return (
        <Card
            title={`正在修改 ${currentIndex + 1} / ${products.length} 的商品`}
            style={{
                maxWidth: '800px',
                margin: '0px auto',
            }}
            styles={{
                body: { paddingTop: '10px', paddingBottom: '8px' }
            }}
            actions={[
                currentIndex > 0 && <Button key="prev" onClick={() => setCurrentIndex(currentIndex - 1)}>上一个商品</Button>,
                currentIndex < products.length - 1 ? (
                    <Button key="next" onClick={handleNext}>下一个商品</Button>
                ) : (
                    <Button key="submit" type="primary" onClick={handleSubmit}>提交</Button>
                )
            ]}

        >
            <Form layout="vertical">
                <Form.Item label="商品图片">
                    <div
                        style={{
                            width: '277.5px',
                            height: '360px',
                            borderRadius: '10px',
                            backgroundImage: `url(${currentProduct.img_url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={handlePreview}
                    />
                </Form.Item>
                <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                    <img alt="preview" style={{ width: '100%' }} src={previewImage} onClick={() => setPreviewVisible(false)}/>
                </Modal>
                <Form.Item label="名称" style={{  }}>
                    <Input
                        value={currentProduct.name}
                        onChange={handleNameChange}
                        placeholder="请输入商品名称"
                    />
                </Form.Item>
                <Form.Item label="价格" style={{ }}>
                    <InputNumber
                        value={currentProduct.price}
                        onChange={handlePriceChange}
                        placeholder="请输入商品价格 (USD)"
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Form>
        </Card>
    );
}

export default EditProductPage;
