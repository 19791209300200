import React from 'react';
import { Card, Button } from 'antd';

const notionUrl = process.env.REACT_APP_NOTION_URL;

function SubmissionSuccessPage({ setPage }) {
    const handleViewProducts = () => {
        window.location.href = notionUrl;
    };

    const handleAddMore = () => {
        setPage('upload');
    };

    return (
        <div>
            <Card
                title={
                    <div style={{
                        marginTop: '25px',
                    }}>
                            感谢您使用Marrrket！
                            <p style={{
                                color: 'gray',
                                fontSize: '14px',
                                marginTop: '0px',
                                marginBottom: '10px',
                            }}>
                                点击右上方 · · · 浮窗收藏Marrrket ↗️
                            </p>
                    </div>}
                style={{
                    maxWidth: '800px',
                    margin: '0 auto',
                    marginTop: '0px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <p>AI正在帮您生成产品描述ing</p>

                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button
                        type="primary"
                        style={{ margin: '10px' }}
                        onClick={handleViewProducts}
                    >
                        查看我的商品
                    </Button>
                    <Button
                        type="default"
                        style={{ margin: '10px' }}
                        onClick={handleAddMore}
                    >
                        继续上传
                    </Button>
                </div>
                <p style={{ color: 'gray', fontSize: '16px' }}>
                    下架您的商品或提供任何其他产品建议欢迎联系Marrrket小助手!
                </p>
                <img src="assi.png" alt="助手二维码" style={{ width: '150px', padding: '0px', margin: '5px 5px' }} />
                <p style={{ color: 'gray', fontSize: '14px' }}>
                    由于AI生成存在延迟，您的商品会在1-2分钟内完成上传，请耐心等待。
                </p>
            </Card>
        </div>

    );
}

export default SubmissionSuccessPage;
