import React, { useState } from 'react';
import UploadPage from './components/UploadPage';
import EditProductPage from './components/EditProductPage';
import SubmissionSuccessPage from './components/SubmissionSuccessPage';
import WelcomePage from './components/WelcomePage';
import './App.css';
import { ConfigProvider } from 'antd';

function App() {
    const [page, setPage] = useState('welcome');
    const [products, setProducts] = useState([]);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#9ac2b4",
                    colorInfo: "#9ac2bc",
                    colorSuccess: "#1677ff",
                    algorithm: true,
                }
            }}
        >
            <div className="App">
                {page === 'upload' ? (
                    <UploadPage setProducts={setProducts} setPage={setPage} />
                ) : page === 'edit' ? (
                    <EditProductPage products={products} setProducts={setProducts} setPage={setPage}/>
                ) : page === 'submissionSuccess' ? (
                    <SubmissionSuccessPage setPage={setPage} />
                ) : page === 'welcome' ? (
                    <WelcomePage setPage={setPage} />
                ) : (
                    <div>未知页面</div>
                )}
            </div>
        </ConfigProvider>
    );
}

export default App;
