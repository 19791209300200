import React from 'react';
import { Card, Button } from 'antd';

const notionUrl = process.env.REACT_APP_NOTION_URL;

function WelcomePage({ setPage }) {
    const handleBuy = () => {
        window.location.href = notionUrl;
        console.log(notionUrl)
    };

    const handleSell = () => {
        setPage('upload');
    };

    return (
        <Card
            title={<img src="/Marrrket.png" alt="Marrrket Logo" style={{ height: '70px', margin: '10px', marginTop: '13px'}} />} // 设置图片作为标题
            style={{
                maxWidth: '800px',
                margin: '100px auto',
                textAlign: 'center',
            }}
        >
            <p>我们致力于为留学生提供更高效的二手闲置买卖服务。</p>


            <Button type="default" style={{ margin: '10px' }} onClick={handleBuy}>
                我要买
            </Button>

            <Button type="primary" style={{ margin: '10px' }} onClick={handleSell}>
                我要卖
            </Button>
        </Card>
    );
}

export default WelcomePage;
