import React, { useEffect, useState } from 'react';
import { Modal, Input, Upload, Button, Radio, message, Card } from 'antd';
import { InboxOutlined, DeleteOutlined} from '@ant-design/icons';




function UploadPage({ setProducts, setPage }) {
    const [fileList, setFileList] = useState([]);
    const [deliveryMethod, setDeliveryMethod] = useState('需自提');
    const [wechatID, setWechatID] = useState('');
    const { Dragger } = Upload;

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const titleContent = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>一键上传批量商品</span>
            {fileList.length > 1 && <span style={{ fontSize: '0.8rem', marginLeft: '10px' }}>向右滑动查看</span>}
        </div>
    );

    const handlePreview = file => {
        setPreviewImage(file.originFileObj ? URL.createObjectURL(file.originFileObj) : '');
        setPreviewVisible(true);
    };

    useEffect(() => {
        const storedWechatID = localStorage.getItem('wechatID');
        if (storedWechatID) {
            setWechatID(storedWechatID);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('wechatID', wechatID);
    }, [wechatID]);

    const props = {
        onRemove: file => {
            setFileList(current => current.filter(f => f.uid !== file.uid));
        },
        beforeUpload: file => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('只能上传 JPG/PNG 文件!');
                return Upload.LIST_IGNORE;
            }
            return false;
        },
        onChange: info => {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            setFileList(info.fileList.filter(file => !!file.originFileObj));
        },
        multiple: true,
        listType: "picture-card",
        fileList
    };

    const handleGenerateProducts = async () => {
        if (fileList.length === 0) {
            message.error('请先上传至少一个商品图片！');
            return;
        }
        if (!wechatID) {
            message.error('请填写微信号！');
            return;
        }
        const generatedProducts = fileList.map((file, index) => ({
            price: null,
            img_url: file.originFileObj ? URL.createObjectURL(file.originFileObj) : null,
            img_file: file.originFileObj,
            wechatID: wechatID,
            deliveryMethod: deliveryMethod,
        }));
        setProducts(generatedProducts);
        setPage('edit');
        message.success('图片上传成功！');
    };

    const handleRemoveFile = (uid) => {
        setFileList(current => current.filter(file => file.uid !== uid));
    };

    return (
        <div style={{  display: 'flex', flexDirection: 'column' }}>
            <Card
                title={titleContent}
                bordered={false}
                style={{ width: '85vw', height: '80vh', marginTop: '3vh',  maxWidth: '400px'}}
            >
                <div style={{
                    overflowX: 'scroll',
                    display: 'flex',
                    marginBottom: '2vh',
                    height: '45vh',
                    alignItems: fileList.length === 0 ? 'center' : 'initial',
                    justifyContent: fileList.length <= 1 ? 'center' : 'initial',

                }}>
                    {fileList.length === 0 ? (
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">点击上传商品图片</p>
                            <p className="ant-upload-hint">单个/多个商品一键上传！</p>
                        </Dragger>
                    ) : (
                        fileList.map(file => (
                            file.originFileObj ? (
                                <div key={file.uid} style={{ position: 'relative', marginRight: fileList.length <= 1 ? '0px' : '8px' }}>
                                    <div
                                        style={{
                                            width: '277.5px',
                                            height: '370px',
                                            borderRadius: '10px',
                                            backgroundImage: `url(${URL.createObjectURL(file.originFileObj)})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}
                                        onClick={() => handlePreview(file)}
                                    ></div>

                                    <Button
                                        icon={<DeleteOutlined />}
                                        shape="circle"
                                        style={{ position: 'absolute', top: '1.5vh', right: '1.5vh' }}
                                        onClick={() => handleRemoveFile(file.uid)}
                                    />
                                </div>
                            ) : null
                        ))
                    )}
                </div>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={() => setPreviewVisible(false)}
                    maskClosable={true}
                >
                    <img alt="preview" style={{ width: '100%' }} src={previewImage} onClick={() => setPreviewVisible(false)}/>
                </Modal>
                <Input
                    placeholder="请输入微信号"
                    value={wechatID}
                    onChange={e => setWechatID(e.target.value)}
                    style={{ marginBottom: '3vh' }}
                />
                <Radio.Group
                    onChange={e => setDeliveryMethod(e.target.value)}
                    value={deliveryMethod}
                    className="custom-radio-button"
                    style={{ display: 'flex', justifyContent: 'center', marginBottom: '3vh' }}
                >
                    <Radio.Button value="可送货" style={{ marginRight: 0}}>可送货</Radio.Button>
                    <Radio.Button value="学校面交">学校面交</Radio.Button>
                    <Radio.Button value="需自提">需自提</Radio.Button>

                </Radio.Group>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2vh'}}>
                    <Button
                        type="primary"
                        onClick={handleGenerateProducts}
                        style={{ maxWidth: '200px', width:'50vw',height: '5vh', fontSize: '16px'}}
                    >
                        去填写商品信息
                    </Button>
                </div>
            </Card>
        </div>
    );
}

export default UploadPage;
